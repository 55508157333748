<template>
    <div class="mainW">
        <div class="mainbox_question">
            <div class="bc">
                <div class="ttil">
                    <div @click="goback()" class="goback">
                        <i class="el-icon-arrow-left"></i> 返回
                    </div> 做题记录

                </div>
                <div class="record">
                    <div class="screen">
                        <!-- 下拉框 -->
                        <el-select @change="selectVipQuestionResult" style="margin-right: 10px;" v-model="value2"
                            placeholder="">
                            <el-option v-for="(item, index) in selectList" :key="index" :label="item.title"
                                :value="item.id">
                            </el-option>
                        </el-select>

                        <!-- 日期 -->
                        <div class="block">
                            <el-date-picker @change="selectVipQuestionResult" format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd" v-model="value1" type="daterange" range-separator="-"
                                start-placeholder="开始日期" end-placeholder="结束日期">
                            </el-date-picker>
                        </div>

                        <!-- 清空记录 -->
                        <div class="clear">
                            <div class="clear-box" @click="showClearFn">
                                <img src="@/assets/img/questionNew/55.png" class="clear-icon" />
                                <span>清空记录</span>
                            </div>
                        </div>
                    </div>
                    <div class="list">
                        <div class="item" v-for="(item, index) in list" :key="index">
                            <div class="item-container">
                                <div class="item-title">
                                    <div>{{ qtype[item.type] }}--{{ item.typeName }}</div>
                                    <div class="item-time">{{ item.addTime }}

                                    </div>
                                </div>
                                <div class="item-info">

                                    <div class="item-num">
                                        <div class="item-num-i">
                                            <img src="@/assets/img/questionNew/46.png"
                                                style="width: 15px; height: 15px" />
                                            <span>用时：{{ item.problemDateTime }}</span>
                                        </div>
                                        <div class="item-num-i">
                                            <img src="@/assets/img/questionNew/47.png"
                                                style="width: 14px; height: 14px" />
                                            <span class="mr8">总题</span>
                                            <span style="color: #4A6AF0">{{ item.allNum }}</span>
                                            <span>题</span>
                                        </div>
                                        <div class="item-num-i">
                                            <img src="@/assets/img/questionNew/48.png"
                                                style="width: 15px; height: 14px" />
                                            <span class="mr8">已做</span>
                                            <span style="color: #4A6AF0">{{ item.makerNum }}</span>
                                            <span>题</span>
                                        </div>
                                        <div class="item-num-i">
                                            <img src="@/assets/img/questionNew/49.png"
                                                style="width: 14px; height: 15px" />
                                            <span class="mr8">做对</span>
                                            <span style="color: #4A6AF0">{{ item.trueNum }}</span>
                                            <span>题</span>
                                        </div>
                                    </div>
                                    <div class="item-but item-but1" @click="see(item)">查看解析</div>
                                    <div class="item-but item-but2" @click="again(item)">重新做题</div>
                                </div>
                            </div>
                        </div>
                        <el-empty description="暂无数据~" v-if="list.length == 0" />
                    </div>

                </div>

            </div>
        </div>
        <!-- 清空记录 -->
        <div class="mask-fixed" v-if="clearShow">
            <div class="clear-toast">
                <div class="clear-title">确定要清空所有记录吗？</div>
                <div class="clear-but">
                    <div class="clear-but-1" @click="showClearFn">取消</div>
                    <div class="clear-but-2" @click="showClearSubmit">确定</div>
                </div>
            </div>
        </div>
        <div class="mask-fixed" style="z-index: 2001" v-if="screenShow">
            <screen :courseNameId="courseNameId" @close="showScreen" />
        </div>
    </div>
</template>
<script>

import Vue from 'vue'
import { getInfo } from '@/api/cookies'
import screen from '@/components/questionNew/screen.vue'
import { qtype } from '@/api/emun'

import { AnswerClient } from '@/api/task/answerClient'
const answerClient = new AnswerClient()
export default {

    components: { screen },
    data() {
        return {
            qtype: qtype,

            courseNameList: [], // 试卷列表
            info: {},
            getInfo: null,
            clearShow: false,
            screenShow: false,

            courseNameId: '',
            list: [],
            selectInfo: {},
            value2: '',
            value1: [],
            selectList: [
                {
                    id: '',
                    title: "全部类型",
                },
                {
                    id: 1,
                    title: "智能练习",
                },
                {
                    id: 2,
                    title: "易错题",
                },
                {
                    id: 4,
                    title: "收藏夹",
                },
                {
                    id: 3,
                    title: "错题本",
                },
            ],
            date: ["", ""]

        }
    },
    async created() {
        this.userInfo = getInfo()
        if (!this.userInfo) {
            Vue.prototype.goLoginView(false)
            return
        }
        this.courseNameId = this.$route.query.courseNameId
        await this.selectVipQuestionResultTime()

        await this.selectVipQuestionResult()

    },

    methods: {
        again(item) {
            if (item.type == 1) {
                this.screenShow = true
            } else {
                this.$router.push({
                    path: "/questionNew/questionAnswerOther",
                    query: {
                        courseNameId: this.courseNameId,
                        questionType: item.questionType,
                        makerType: item.makerType,
                        type: item.type,
                        xmtype: 0,
                        questionTypeName: item.typeName,
                        isbeiTi: 0
                    },
                });
            }

        },
        see(item) {

            this.$router.push({
                path: "/questionNew/questionAnswerAly",
                query: {
                    resultId: item.id,

                    isbeiTi: 1
                },
            });



        },
        showScreen() {

            this.screenShow = !this.screenShow;

        },
        // 清空记录
        showClearFn() {
            this.clearShow = !this.clearShow;
        },
        async selectVipQuestionResult() {
            console.log(this.value1, 'this.value1');

            await answerClient.selectVipQuestionResult({
                courseId: this.courseNameId,
                type: this.value2 ? this.value2 : undefined,
                startTime: this.value1 && this.value1.length > 0 ? this.value1[0] : undefined,
                endTime: this.value1 && this.value1.length > 0 ? this.value1[1] : undefined
            }).then(async res => {
                this.list = res.data

            })
        },
        async selectVipQuestionResultTime() {
            await answerClient.selectVipQuestionResultTime({ courseId: this.courseNameId, }).then(async res => {

                if (res.data.endTime) {
                    this.value1 = [res.data.startTime, res.data.endTime]
                }

            })
        },
        showClearSubmit() {
            answerClient
                .deleteVipQuestionResult({
                    courseId: this.courseNameId,
                })
                .then(async () => {
                    this.value1 = []
                    this.value2 = ''
                    await this.selectVipQuestionResultTime()
                    await this.selectVipQuestionResult()
                    this.clearShow = false
                });
        },


        // 消灭错题
        start(item, index) {
            sessionStorage.setItem("fallibleTips", JSON.stringify(item))
            this.$router.push({
                path: "/questionNew/questionAnswerOther",
                query: {
                    courseNameId: this.courseNameId,
                    questionType: item.questionType,
                    makerType: 2,
                    type: 4,
                    xmtype: 1,
                    questionTypeName: item.questionTypeName,
                    isbeiTi: index
                },
            });
        },
        goback() {
            this.$router.go(-1)
        }


    }
}
</script>
<style lang="less" scoped>
.mainW {
    background: #fff;
    border-radius: 0 0 8px 8px;
}

.mainbox_question {
    width: 100%;
    background-image: url("~@/assets/img/questionNew/52.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    padding: 24px 28px 0;
    border-radius: 8px;
}

.record {
    padding: 10px 0 0 0;
    flex: 1;
    // height: 0;
    display: flex;
    flex-direction: column;

    .screen {
        padding-right: 10px;
        display: flex;
        align-items: center;

        .select {
            width: 256px;
            height: 43px;
            border-radius: 4px;
            background-color: #f5f7f9;
            display: flex;
            align-items: center;
            padding: 0 16px;
            margin-right: 18px;
            position: relative;
            cursor: pointer;

            .select-name {
                flex: 1;
                font-size: 14px;
                color: #333;
            }

            .select-list {
                width: 100%;
                position: absolute;
                left: 0;
                top: 100%;
                z-index: 5;
                transform: translateY(6px);
                border-radius: 8px;
                box-shadow: 0px 0px 16px 1px rgba(0, 39, 135, 0.14);

                .select-item {
                    width: 100%;
                    height: 43px;
                    background-color: #fff;
                    font-size: 13px;
                    color: #777;
                    line-height: 43px;
                    padding-left: 16px;

                    &:first-child {
                        border-radius: 8px 8px 0 0;
                    }

                    &:last-child {
                        border-radius: 0 0 8px 8px;
                    }

                    &:hover {
                        background-color: #f2f8ff;
                        color: #333;
                    }
                }

                .select-check {
                    background-color: #f2f8ff;
                    color: #333;
                }
            }

            .selectInput {
                opacity: 0;
                position: fixed;
                left: -99999px;
                top: -999999px;
                z-index: -10000;
            }
        }

        .date {
            width: 320px;
            height: 43px;
            border-radius: 4px;
            background-color: #f5f7f9;
            padding: 0 16px;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #333;
            cursor: pointer;
            position: relative;

            .date-time {
                flex: 1;
            }

            .date-close {
                width: 15px;
                height: 15px;
                margin: 0 12px;
            }

            .date-icon {
                width: 16px;
                height: 14px;
            }

            :deep(.el-date-editor) {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                z-index: -1;
                opacity: 0;
            }
        }

        .clear {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: 14px;
            color: #4A6AF0;

            .clear-box {
                display: flex;
                align-items: center;
                cursor: pointer;
            }

            .clear-icon {
                width: 15px;
                height: 15px;
                margin-right: 8px;
            }
        }
    }

    .list {
        flex: 1;
        margin-top: 20px;
        // padding-right: 24px;
        overflow-x: hidden;
        overflow-y: auto;

        .item {
            width: 100%;
            height: 111px;
            border-radius: 14px;
            background: linear-gradient(360deg, #7EC8FF 0%, #CDEAFC 65%, #CEECFC 100%);
            margin-bottom: 20px;
            padding: 8px;

            &:last-child {
                margin-bottom: 0;
            }

            .item-container {
                width: 100%;
                height: 100%;
                background-color: #fff;
                border-radius: 12px;
                padding: 15px 20px;

                .item-title {
                    font-size: 16px;
                    color: #333;
                    font-weight: bold;
                    display: flex;
                    justify-content: space-between;

                    .item-time {
                        font-size: 14px;
                        color: #777;
                        font-weight: 400;
                    }
                }

                .item-info {
                    display: flex;
                    align-items: flex-end;
                    margin-top: 5px;



                    .item-num {
                        display: flex;
                        align-items: center;
                        flex: 1;

                        .item-num-i {
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            color: #777;
                            margin-right: 24px;

                            &:last-child {
                                margin-right: 0;
                            }

                            img {
                                margin-right: 6px;
                            }
                        }
                    }

                    .item-but {
                        width: 100px;
                        height: 36px;
                        border-radius: 18px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 15px;
                        cursor: pointer;
                    }

                    .item-but1 {
                        border: 1px solid #4A6AF0;
                        color: #4A6AF0;
                    }

                    .item-but2 {
                        background-color: #4A6AF0;
                        color: #fff;
                        margin-left: 12px;
                    }
                }
            }
        }
    }

    :deep(.el-pagination) {
        padding: 20px !important;
    }
}

.clear-toast {
    width: 383px;
    border-radius: 12px;
    background-color: #fff;
    padding: 28px 0;

    .clear-title {
        font-size: 20px;
        color: #333;
        text-align: center;
        margin-bottom: 32px;
    }

    .clear-but {
        display: flex;
        align-items: center;
        justify-content: center;

        div {
            width: 142px;
            height: 42px;
            border-radius: 21px;
            font-size: 16px;
            text-align: center;
            line-height: 42px;
            margin: 0 12px;
            font-weight: 600;
            cursor: pointer;
        }

        .clear-but-1 {
            background-color: #ECF0FE;
            color: #4A6AF0;
        }

        .clear-but-2 {
            background-color: #4A6AF0;
            color: #fff;
        }
    }
}

.bc {
    background: #fff;
    padding: 16px;
    border-radius: 8px;

    .ttil {
        position: relative;
        text-align: center;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: bold;
        font-size: 20px;
        color: #333333;
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .goback {
        position: absolute;


        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        font-size: 16px;
        color: #777777;
        top: 2px;
        cursor: pointer;
    }
}

::v-deep .el-input__inner,
::v-deep .el-range-input {
    background: #F6F6F6;
    color: #333333;
}

::v-deep .el-input__inner {
    border: none;
}
</style>
